<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import { masterMethods, prizeMethods, partnersMethods, checkPermission, keyMaster, isValidDate } from '@/state/helpers';
import Swal from 'sweetalert2';
import DatePicker from 'vue2-datepicker';
import { maxLength, required } from 'vuelidate/lib/validators';
import LoadingIcon from '@/components/Loading/main.vue';
import { renderLineBreaks } from '@/utils/format';
import Footer from '@/components/Footer/main.vue';
import { showMessage } from '@/utils/messages';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '賞品詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        DatePicker,
        Footer
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: {
                name: '',
                campaign: null,
                kana_name: '',
                email: '',
                phone_number: '',
                type: {
                    id: ''
                },
                note: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                keyword: ''
            },
            listDataMasterTeam: [],
            listDataMasterCompare: [],
            listReturnPrize: [],
            listDataReturn: [],
            submitted: false,
            checkShowAdd: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            return_prize: {
                prize: {
                    id: this.$route.query.id
                },
                end_flag: true,
                number: 1,
                return_date: '',
                province: '',
                contact_information_number: '',
                reason: '',
                return_status: {
                    id: ''
                },
                reconfirmation_schedule: '',
                instruction: '',
                compatiable_with_jp_logistic_development: '',
                storage_code: '',
                note: ''
            },
            customFormatDate: 'YYYY-MM-DD'
        };
    },
    validations: {
        listReturnPrize: {
            $each: {
                return_date: {
                    validate(value) {
                        if (value) {
                            // Tách năm, tháng và ngày từ chuỗi đầu vào
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];

                            // Tạo đối tượng Date từ các phần tử đã tách
                            var dateObject = new Date(year, month - 1, day);

                            // Lấy các phần tử ngày, tháng, năm từ đối tượng Date
                            var dayNew = dateObject.getDate();
                            var monthNew = dateObject.getMonth() + 1;
                            var yearNew = dateObject.getFullYear();

                            // Định dạng lại chuỗi theo định dạng mới "dd-mm-yyyy"
                            var result = dayNew + '/' + monthNew + '/' + yearNew;

                            return isValidDate(result);
                        }
                        return true;
                    }
                },
                province: { maxLength: maxLength(255) },
                reconfirmation_schedule: {
                    required,
                    validate(value) {
                        if (value) {
                            // Tách năm, tháng và ngày từ chuỗi đầu vào
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];

                            // Tạo đối tượng Date từ các phần tử đã tách
                            var dateObject = new Date(year, month - 1, day);

                            // Lấy các phần tử ngày, tháng, năm từ đối tượng Date
                            var dayNew = dateObject.getDate();
                            var monthNew = dateObject.getMonth() + 1;
                            var yearNew = dateObject.getFullYear();

                            // Định dạng lại chuỗi theo định dạng mới "dd-mm-yyyy"
                            var result = dayNew + '/' + monthNew + '/' + yearNew;
                            return isValidDate(result);
                        }
                        return true;
                    }
                },
                return_status: {
                    validate(value) {
                        if (value.id) {
                            return true;
                        }
                        return isValidDate(value.id);
                    }
                },
                contact_information_number: { maxLength: maxLength(255) },
                reason: { required, maxLength: maxLength(255) },
                instruction: { maxLength: maxLength(255) },
                compatiable_with_jp_logistic_development: { maxLength: maxLength(255) },
                storage_code: { maxLength: maxLength(255) },
                note: { maxLength: maxLength(255) }
            }
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
            this.getListMasterCompare();
            this.getDataListPrizeReturn();
        }
    },
    methods: {
        ...masterMethods,
        ...prizeMethods,
        ...partnersMethods,
        getFormattedDate() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getListMasterCompare() {
            this.listMaster(keyMaster['prize.prize_less_status']).then((data) => {
                this.listDataMasterCompare = data;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailPrize(id).then((data) => {
                this.form = data;
                this.form.campaign = data.event_prizes.campaign;
                this.loading = false;
            });
        },
        goToEdit(path, id, partner_id) {
            this.$router.push({ path: path, query: { id: id, partner_id: partner_id } });
        },
        formatDateToChinese(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
            const date = new Date(time);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            return `${year} 年 ${month} 月 ${day} 日`;
        },
        addReturnPrize() {
            let obj = JSON.parse(JSON.stringify(this.return_prize));
            obj.return_date = this.getFormattedDate();
            this.listReturnPrize.push(obj);
        },
        deleteReturnPrize(k) {
            if (k >= 0) {
                this.listReturnPrize.splice(k, 1);
            }
        },
        deleteObject(data) {
            Swal.fire({
                title: 'この賞品を削除してもよろしいですか',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deletePrize(data).then((data) => {
                        if (data && data.code == 200) {
                            this.$router.push({ path: '/prize/list' }).then(() => {
                                showMessage(data.code, this.$bvToast, '賞品が削除されました。');
                            });
                        }
                    });
                }
            });
        },
        deletePrizeRe(data) {
            Swal.fire({
                title: '賞品戻りを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deletePrizeReturn(data).then((data) => {
                        if (data && data.code == 200) {
                            showMessage(data.code, this.$bvToast, '賞品戻りが削除されました。');
                            this.getDataListPrizeReturn();
                        }
                    });
                }
            });
        },
        getDataListPrizeReturn() {
            let query = `filters[prize]=${this.$route.query.id}&limit=99&sorts[id]=asc`;
            this.listPrizeReturn(query).then((data) => {
                if (data && data.code == 200) {
                    data.data.forEach((item) => {
                        if (!item.return_status) item.return_status = { id: '' };
                    });
                    this.listDataReturn = data.data;
                }
            });
        },
        saveReturnPrize() {
            if (!this.checkPermiss('return_prize.edit')) return;
            this.registerPrizeReturn(this.listReturnPrize).then(() => {
                this.submitted = false;
                this.getDataListPrizeReturn();
                this.listReturnPrize = [];
            });
        },
        formSubmit() {
            if (this.listReturnPrize.length <= 0) return;
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                this.saveReturnPrize();
            }
        },
        saveItem(item) {
            this.updatePrizeReturn(item).then((data) => {
                if (data.code == 422) {
                    this.$bvToast.toast(`error`, {
                        title: this.$t(data.message),
                        variant: 'danger',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                }
            });
        },
        textLineBreaks(text) {
            return renderLineBreaks(text);
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                            <div class="row justify-content-center">
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">キャンペーン名</label>
                                        <div class="col-sm-9">
                                            {{ form.campaign ? form.campaign.name : '' }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">賞品名</label>
                                        <div class="col-sm-9">
                                            {{ form.name }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">告知</label>
                                        <div class="col-sm-9">
                                            {{ form.campaign ? form.campaign.inform : '' }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">サイト</label>
                                        <div class="col-sm-9">
                                            <a v-if="form.campaign" target="_blank" :href="form.campaign.url">{{
                                                form.campaign ? form.campaign.url : ''
                                            }}</a>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">応募方法</label>
                                        <div class="col-sm-9">
                                            <p v-if="form.campaign" v-html="textLineBreaks(form.campaign.application_method)"></p>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">抽選者数</label>
                                        <div class="col-sm-9">
                                            {{ form ? form.number_of_people_drawing_lots : 0 }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">賞品発送時期</label>
                                        <div class="col-sm-9">
                                            {{ form.campaign ? form.campaign.prize_shipping_period : '' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h5><b>賞品戻り</b></h5>

                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered">
                                    <thead>
                                        <tr>
                                            <th>終了FLG</th>
                                            <th>No.</th>
                                            <th>戻り日</th>
                                            <th>キャンペーン名</th>
                                            <th>都道府県</th>
                                            <th>運送会社</th>
                                            <th>問合せ№</th>
                                            <th>戻り理由</th>
                                            <th>対応指示</th>
                                            <th>再確認日程</th>
                                            <th>パルディアより対応指示項目</th>
                                            <th>日本物流開発様対応</th>
                                            <th>保管棚番</th>
                                            <th>備考</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="listDataReturn.length > 0">
                                        <template>
                                            <tr v-for="(item, k) in listDataReturn" :key="'c' + k">
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            v-model="item.end_flag"
                                                            class="custom-control-input"
                                                            value="true"
                                                            :id="`__BVID__742` + k"
                                                        />
                                                        <label class="custom-control-label" :for="`__BVID__742` + k"><label></label></label>
                                                    </div>
                                                </td>

                                                <td>
                                                    {{ k + 1 }}
                                                </td>

                                                <td style="width: 123px">
                                                    <date-picker
                                                        @change="saveItem(item)"
                                                        style="width: 123px"
                                                        ref="return_date"
                                                        v-model="item.return_date"
                                                        :value-type="customFormatDate"
                                                        :first-day-of-week="1"
                                                        lang="ja"
                                                    ></date-picker>
                                                </td>

                                                <td>
                                                    {{ form.campaign ? form.campaign.name : '' }}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        v-model="item.province"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        @change="saveItem(item)"
                                                        v-model="item.prize.delivery_company"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        style="width: 140px"
                                                        v-model="item.contact_information_number"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td class="input-group-sm">
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        style="width: 140px"
                                                        v-model="item.reason"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td class="input-group-sm">
                                                    <select
                                                        class="form-control"
                                                        @change="saveItem(item)"
                                                        v-model="item.return_status.id"
                                                        style="width: 190px"
                                                    >
                                                        <option :value="i.id" v-for="i in listDataMasterCompare" :key="i.id">{{ i.value }}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <date-picker
                                                        @change="saveItem(item)"
                                                        style="width: 123px"
                                                        ref="scheduled_arrived_at"
                                                        v-model="item.reconfirmation_schedule"
                                                        :value-type="customFormatDate"
                                                        :first-day-of-week="1"
                                                        lang="ja"
                                                    ></date-picker>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        v-model="item.instruction"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        v-model="item.compatiable_with_jp_logistic_development"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        v-model="item.storage_code"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        @change="saveItem(item)"
                                                        v-model="item.note"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                    />
                                                </td>
                                                <td align="center">
                                                    <button
                                                        class="btn btn-sm btn-outline-danger"
                                                        v-if="checkPermiss('return_prize.delete')"
                                                        type="button"
                                                        @click="deletePrizeRe(item)"
                                                        style="border-radius: 50%"
                                                    >
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tbody v-else>
                                        <template>
                                            <tr style="position: relative; height: 80px">
                                                <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                    <p class="mt-3 text-center">
                                                        {{ $t('notificationCommon.noData') }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <hr class="my-5" />

                            <div class="table-responsive" v-if="checkShowAdd">
                                <table class="table mb-0 table-bordered">
                                    <thead>
                                        <tr>
                                            <th>終了FLG</th>
                                            <th>No.</th>
                                            <th>戻り日 <span class="text-danger">*</span></th>
                                            <th>キャンペーン名</th>
                                            <th>都道府県</th>
                                            <th>問合せ№</th>
                                            <th>戻り理由 <span class="text-danger">*</span></th>
                                            <th>対応指示 <span class="text-danger">*</span></th>
                                            <th>再確認日程</th>
                                            <th>パルディアより対応指示項目</th>
                                            <th>日本物流開発様対応</th>
                                            <th>保管棚番</th>
                                            <th>備考</th>
                                            <th>
                                                <button
                                                    class="btn btn-sm btn-outline-info"
                                                    @click="addReturnPrize()"
                                                    type="button"
                                                    style="border-radius: 50%"
                                                >
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                                <button
                                                    class="btn btn-sm btn-outline-danger ml-2"
                                                    @click="
                                                        checkShowAdd = false;
                                                        listReturnPrize = [];
                                                    "
                                                    type="button"
                                                    style="border-radius: 50%"
                                                >
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="listReturnPrize.length > 0">
                                        <template>
                                            <tr v-for="(item, k) in listReturnPrize" :key="item.id + 'c' + k">
                                                <td>
                                                    <div class="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            v-model="item.end_flag"
                                                            class="custom-control-input"
                                                            value="true"
                                                            :id="`__2BVID__742` + k"
                                                        />
                                                        <label class="custom-control-label" :for="`__2BVID__742` + k"><label></label></label>
                                                    </div>
                                                </td>

                                                <td></td>

                                                <td style="width: 123px">
                                                    <div
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].return_date.$error
                                                        }"
                                                    >
                                                        <date-picker
                                                            style="width: 123px"
                                                            ref="return_date"
                                                            v-model="item.return_date"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </div>
                                                    <div v-if="submitted && $v.listReturnPrize.$each[k].return_date.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listReturnPrize.$each[k].return_date.validate">{{
                                                            $t('validateField.incorrectFormat', {
                                                                field: '戻り日'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    {{ form.campaign ? form.campaign.name : '' }}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        v-model="item.province"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].province.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.listReturnPrize.$each[k].province.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listReturnPrize.$each[k].province.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '都道府県'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        style="width: 140px"
                                                        v-model="item.contact_information_number"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].contact_information_number.$error
                                                        }"
                                                    />
                                                    <div
                                                        v-if="submitted && $v.listReturnPrize.$each[k].contact_information_number.$error"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.listReturnPrize.$each[k].contact_information_number.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '問合せ№'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td class="input-group-sm">
                                                    <input
                                                        type="text"
                                                        style="width: 140px"
                                                        v-model="item.reason"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].reason.$error
                                                        }"
                                                    />

                                                    <div v-if="submitted && $v.listReturnPrize.$each[k].reason.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listReturnPrize.$each[k].reason.required">{{
                                                            $t('validateField.required', {
                                                                field: '戻り理由'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.listReturnPrize.$each[k].reason.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '戻り理由'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td class="input-group-sm">
                                                    <select
                                                        class="form-control"
                                                        v-model="item.return_status.id"
                                                        style="width: 190px"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].return_status.$error
                                                        }"
                                                    >
                                                        <option :value="i.id" v-for="i in listDataMasterCompare" :key="i.id">{{ i.value }}</option>
                                                    </select>

                                                    <div
                                                        v-if="submitted && $v.listReturnPrize.$each[k].return_status.$error"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!item.return_status.id">{{
                                                            $t('validateField.requiredSelect', {
                                                                field: '対応指示'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].reconfirmation_schedule.$error
                                                        }"
                                                    >
                                                        <date-picker
                                                            style="width: 123px"
                                                            ref="scheduled_arrived_at"
                                                            v-model="item.reconfirmation_schedule"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </div>
                                                    <div
                                                        v-if="submitted && $v.listReturnPrize.$each[k].reconfirmation_schedule.$error"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.listReturnPrize.$each[k].reconfirmation_schedule.required">{{
                                                            $t('validateField.required', {
                                                                field: '再確認日程'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.listReturnPrize.$each[k].reconfirmation_schedule.validate">{{
                                                            $t('validateField.incorrectFormat', {
                                                                field: '再確認日程'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        v-model="item.instruction"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].instruction.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.listReturnPrize.$each[k].instruction.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listReturnPrize.$each[k].instruction.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '問合せ№'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        v-model="item.compatiable_with_jp_logistic_development"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                $v.listReturnPrize.$each[k].compatiable_with_jp_logistic_development.$error
                                                        }"
                                                    />
                                                    <div
                                                        v-if="
                                                            submitted && $v.listReturnPrize.$each[k].compatiable_with_jp_logistic_development.$error
                                                        "
                                                        class="invalid-feedback"
                                                    >
                                                        <span
                                                            v-if="!$v.listReturnPrize.$each[k].compatiable_with_jp_logistic_development.maxLength"
                                                            >{{
                                                                $t('validateField.max255', {
                                                                    field: '日本物流開発様対応'
                                                                })
                                                            }}</span
                                                        >
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        v-model="item.storage_code"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].storage_code.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.listReturnPrize.$each[k].storage_code.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listReturnPrize.$each[k].storage_code.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '保管棚番'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        v-model="item.note"
                                                        style="width: 140px"
                                                        placeholder=""
                                                        class="form-control-sm form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.listReturnPrize.$each[k].note.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.listReturnPrize.$each[k].note.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listReturnPrize.$each[k].note.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '備考'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </td>
                                                <td align="center">
                                                    <button
                                                        class="btn btn-sm btn-outline-danger"
                                                        type="button"
                                                        @click="deleteReturnPrize(k)"
                                                        style="border-radius: 50%"
                                                    >
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tbody v-else>
                                        <template>
                                            <tr style="position: relative; height: 80px">
                                                <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                    <p class="mt-3 text-center">
                                                        {{ $t('notificationCommon.noData') }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>

                            <div class="text-center mt-4">
                                <button type="submit" v-if="checkPermiss('return_prize.register') && checkShowAdd" class="btn btn-primary">
                                    賞品戻り登録
                                </button>
                                <button
                                    type="button"
                                    v-if="checkPermiss('return_prize.register') && !checkShowAdd"
                                    @click="checkShowAdd = true"
                                    class="btn btn-primary"
                                >
                                    賞品戻り登録
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/prize/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermiss('prize.register')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/prize/form', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('prize.delete')"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style lang="scss">
th,
td {
    white-space: nowrap;
}
th:last-child,
td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
}
.mx-input {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    min-width: 123px;
}
</style>
